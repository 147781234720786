import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  Chip,
  Menu,
  MenuItem
} from '@mui/material'

import { updateCurrent } from '../../../../app/orgSlice'

import ChangeOrganization from './ChangeOrganization'
import AdminChip from '../../../../components/AdminChip'

export default function Organization() {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = !!anchorEl
  const org = useSelector((state) => state.org.currentOrg)
  const orgs = useSelector((state) => state.org.orgs)
  const user = useSelector((state) => state.user.value)

  const dispatch = useDispatch()

  const changeOrg = (i) => {
    dispatch(updateCurrent(orgs[i]))
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  console.log(orgs)

  return (
    <Box maxWidth='100%'>
      <Paper sx={{ minHeight: 240, textAlign: 'left' }}>
        <Box p={2} minHeight='208px' display='flex' flexDirection='column' justifyContent='space-between'>
          <Box>
            <Typography variant='h6' color='primary'>Organization</Typography>
          </Box>
          <Box>
            <Box display='flex' alignItems='center'>
              <Typography variant='h4'>{org.name}</Typography>
              {
                org.isAdmin &&
                <Box pl={2}>
                  <AdminChip />
                </Box>
                
              }
            </Box>
            <Typography variant='h6'>{user.googleUserID || user.email}</Typography>
          </Box>
          {
            orgs.length > 1 &&
            <Box>
              <Button 
                variant='outlined'
                onClick={e => setAnchorEl(e.currentTarget)}
              >Change Organization</Button>
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
              >
                {
                  orgs.map((org, i) => (
                    <MenuItem
                      key={org + i}
                      onClick={() => changeOrg(i)}
                    >
                      {org.name}
                    </MenuItem>
                  ))
                }
              </Menu>
            </Box>
          }
        </Box>
      </Paper>
    </Box>
  )
}
