import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import useAsync from '../../hooks/useAsync'
import { useSnackbar } from '../../contexts/snackbarContext'

const Bug = () => {
  const [body, setBody] = useState('')

  const { create } = useSnackbar()

  const [
    bugTicket,
    createTicket,
    resetRequest
  ] = useAsync('post', 'createBugTicket')

  useEffect(() => {
    return () => resetRequest()
  }, [])

  
  useEffect(() => {
    if (bugTicket.status === 'success') {
      create('success', bugTicket.value.data)
    }
    if (bugTicket.status === 'error') {
      create('error', 'Problem submitting ticket.')
    }
  }, [bugTicket])

  return (
    <Container disableGutters>
      <Box>
        <Paper>
          <Box p={2}>
            <Box pb={2}>
              <Typography variant='h6'>
                Submit Feedback
              </Typography>
            </Box>
            <TextField 
              value={body}
              onChange={e => setBody(e.target.value)}
              fullWidth 
              label='Description' 
              placeholder='Please write an explanation of your problem here'
              multiline
              rows={6}
            />
            <Box pt={2} display='flex' justifyContent='space-around'>
              <LoadingButton 
                loading={bugTicket.status === 'pending'} 
                variant='contained'
                onClick={() => createTicket({description: body})}
              >Submit</LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default Bug