import React from 'react'
import {
  Box,
  CircularProgress
} from '@mui/material'

const FullPageSpinner = () => {
  return (
    <Box display='flex' width='100%' height='100vh' alignItems='center' justifyContent='space-around'>
      <CircularProgress size={80}/>
    </Box>
  )
}

export default FullPageSpinner