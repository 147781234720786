import React from 'react'
import {
  Box,
  Toolbar,
  Paper,
  Typography,
  Grid
} from '@mui/material'

export default function Requests() {
  return (
    <Box>
        <Box display='flex' maxHeight='100%'>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <Box flexGrow={1} height='100%' width='100%'>
                <Paper style={{height: '100%', width: '100%'}}>
                    <Box p={2}>
                      <Typography variant='h6' color='primary'>
                        Sent Requests
                      </Typography>
                      <Typography>
                        You have no sent requests.
                      </Typography>
                    </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Paper style={{height: '100%'}}>
                <Box p={2}>
                  <Typography variant='h6' color='primary'>
                    Received Requests
                  </Typography>
                  <Typography>
                    You have no received requests.
                  </Typography>
                </Box>
            </Paper>
          </Grid>
          </Grid>
          
        </Box>
    </Box>
  )
}
