import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './app/store'
import { PublicClientApplication } from "@azure/msal-browser"
import './index.css'
import App from './App'
import { msalConfig } from "./authConfig"
import LEThemeProvider from './components/Theme/LEThemeProvider'
import SnackbarContextProvider from './contexts/snackbarContext'

export const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
    <LEThemeProvider>
      <SnackbarContextProvider>
        <Provider store={store}>
          <App msalInstance={msalInstance}/>
        </Provider>
      </SnackbarContextProvider>
    </LEThemeProvider>,
  document.getElementById('root')
)