import React from 'react'
import {
  Link
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Avatar as MuiAvatar,
  Menu,
  Box,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material'

import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import { useMsal } from "@azure/msal-react"
import { BrowserUtils } from "@azure/msal-browser"

import './avatar.css'

const logout = (instance) => {
  console.log(instance)
  instance.logoutRedirect({
    postLogoutRedirectUri: "/",
  })
}

const Avatar = ({ instance }) => {
  const user = useSelector((state) => state.user.value)
  
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  if (user) return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MuiAvatar sx={{ width: 32, height: 32 }} alt={user.googleUserID || user.email} src={user.pictureURL}></MuiAvatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: .5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to='/account'>
          <MuiAvatar alt={user.googleUserID || user.email} src={user.pictureURL}/> My account
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to='/bug'>
          <ListItemIcon>
            <PriorityHighIcon fontSize="small" />
          </ListItemIcon>
          Report a bug
        </MenuItem>
        <MenuItem onClick={() => logout(instance)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
  return null
}

export default Avatar