import * as React from 'react'
import { Snackbar, Alert, Slide } from '@mui/material'
import { useSnackbar } from '../contexts/snackbarContext'

const SnackBar = () => {
  const { snack, clear } = useSnackbar()
  console.log('test')
  console.log(snack)
  !!snack && console.log('showing snack')

  const Transition = (props) => {
    return <Slide {...props} direction={'up'} />
  }

  return (
    <Snackbar
      open={!!snack}
      autoHideDuration={6000}
      onClose={clear}
      TransitionComponent={Transition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={clear} severity={snack?.variant} sx={{ width: '100%' }} variant='filled'>
        {snack?.content}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar