import React, { useEffect } from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'

import Sheet from './Sheet/Sheet'
import TimesheetsList from './TimesheetsList'

import LoadingSkeleton from '../../components/LoadingSkeleton'
import Error from '../../components/Error'

import { useTimeSheet } from './timesheetContext'

const Timesheets = () => {
  const { isLoading, hasError } = useTimeSheet()

  if (isLoading) return <LoadingSkeleton />  

  if (hasError) return <Error />

  return (
    <Routes>
      <Route exact path='/:id' element={<Sheet />} />
      <Route path='/' element={<TimesheetsList />} />
    </Routes>
  )
}

export default Timesheets