import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from './tokenSlice'
import orgReducer from './orgSlice'
import userReducer from './userSlice'

export default configureStore({
  reducer: {
    token: tokenReducer,
    org: orgReducer,
    user: userReducer
  },
})