import React from 'react'
import { Skeleton, Box } from '@mui/material'

const LoadingSkeleton = () => {
  return (
    <Box width='100%'>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Box>
  )
}

export default LoadingSkeleton