import React, { useState } from 'react'
import { useTime } from '../timeContext'
import { Link } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  Typography,
  IconButton,
  Box,
  Button
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import AddIcon from '@mui/icons-material/Add'

import moment from 'moment'

import EventModal from '../Calendar/components/modals/EventModal'
import CreateSheetModal from './CreateSheetModal'

const SimpleEvents = () => {
  const { events } = useTime()
  const [nameFilter, setNameFilter] = useState('')
  const [earlyDateFilter, setEarlyDateFilter] = useState(false)
  const [lateDateFilter, setLateDateFilter] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [currentEvent, setCurrentEvent] = useState(false)

  const [eventDialogOpen, setEventDialogOpen] = useState(false)
  const [createSheetDialogOpen, setCreateSheetDialogOpen] = useState(false)

  const eventFilter = (item) => {
    if (nameFilter.length && !item.summary.includes(nameFilter)) return false
    if (earlyDateFilter && item.start < earlyDateFilter) return false
    if (lateDateFilter && item.end > lateDateFilter) return false
    
    return true
  }

  const handleCheck = (checked, newEvent, index) => {
    console.log(newEvent)
    if (checked) {
      // toggling on
      if (selectedEvents.filter(event => event.eventID === newEvent.eventID).length > 0) return

      setSelectedEvents(oldArray => [...oldArray, {id: newEvent.eventID, index}])
    } else {
      setSelectedEvents(oldArray => oldArray.filter(item => item.id !== newEvent.eventID))
    }
  }

  const editEvent = (event) => {
    setEventDialogOpen(true)
    setCurrentEvent(event)
  }

  const transformedEvents = events.map(event => {
    let newEvent = event
    newEvent.start = new Date(event.start)
    newEvent.end = new Date(event.end)

    return newEvent
  })

  const filteredEvents = transformedEvents.filter(eventFilter)

  const handleMasterCheck = () => {
    console.log(selectedEvents)
    console.log(filteredEvents)
    if (selectedEvents.length === filteredEvents.length) {
      // Currently all are selected - deselect all
      setSelectedEvents([])
    } else {
      const filteredEventIds = filteredEvents.map(item => {return {id: item.eventID}})
      setSelectedEvents(filteredEventIds)
    }
  }

  console.log('SELECTED EVENTS:', selectedEvents)

  return (
    <>
      <TableContainer component={Paper} variant='outlined' sx={{ maxHeight: 'calc(100vh - 150px)' }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
          <TableHead sx={{ bgcolor: 'rgb(231, 235, 240)' }}>
            <TableRow>
              <TableCell style={{ width: '40px' }}>
                <Checkbox 
                  checked={selectedEvents.length > 0 && selectedEvents.length !== filteredEvents.length}
                  indeterminate={selectedEvents.length === filteredEvents.length} 
                  onChange={() => handleMasterCheck()}
                />
              </TableCell>
              <TableCell style={{ width: '270px' }}>
                <Typography>
                  Title
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  Start Date
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  End Date
                </Typography>
              </TableCell>
              <TableCell align="right">
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvents.map((row, index) => {
              console.log('row', row)
                return (
                  <TableRow
                    hover
                    key={row.name || row.title + index || row.summary + index}
                    //onClick={e => toggleSheet(!selectedSheets.filter(sheet => sheet.id === row.id).length, row.id, index)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ maxWidth: '50px' }}>
                      <Checkbox 
                        onChange={e => handleCheck(e.target.checked, row, index)}
                        checked={selectedEvents.filter(item => item.id === row.eventID).length > 0}
                      />
                    </TableCell>
                    <TableCell>
                      {row.name || row.title || row.summary}
                    </TableCell>
                    <TableCell align="right">{moment(row.start).format('lll')}</TableCell>
                    <TableCell align="right">{moment(row.end).format('lll')}</TableCell>
                    <TableCell align="right">
                        <IconButton onClick={() => editEvent(row)}>
                          <EditIcon />
                        </IconButton>
                    </TableCell>
                  </TableRow>
                )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={2} width='100%'>
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Box width='150.83px'></Box>
          <Button 
            variant='outlined'
            component={Link}
            to='/time/import'
          >
            <DownloadIcon />
            <Box pl={1}>
              Import
            </Box>
          </Button>
          <Button 
            variant='outlined'
            disabled={selectedEvents.length === 0}
            onClick={() => setCreateSheetDialogOpen(true)}
          >
            <AddIcon />
            <Box pl={1}>
              New Sheet
            </Box>
          </Button>
        </Box>
      </Box>
      <EventModal
        setEventDialogOpen={setEventDialogOpen}
        eventDialogOpen={eventDialogOpen}
        event={currentEvent}
      />
      <CreateSheetModal 
        createTimesheetDialog={createSheetDialogOpen}
        closeDialog={() => setCreateSheetDialogOpen(false)}
        events={selectedEvents}
      />
    </>
  )
}

export default SimpleEvents