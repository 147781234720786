import { useReducer, useMemo, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update } from '../app/tokenSlice'
import {silentRequest} from '../authConfig'

import axios from 'axios'

import { useMsal } from '@azure/msal-react'
import {InteractionRequiredAuthError} from '@azure/msal-browser'

const initialState = {
  status: 'idle',
  value: null,
  error: null
}

function asyncReducer(state, action) {
  switch (action.type) {
    case 'idle':
      return {
        status: 'idle',
        value: null,
        error: null
      }
    case 'pending':
      return {
        status: 'pending',
        value: null,
        error: null
      }
    case 'success':
      return {
        status: 'success',
        value: action.payload,
        error: null
      }
    case 'error':
      return {
        status: 'error',
        value: null,
        error: action.payload
      }
    default:
      return initialState
  }
}

const useAsync = (method, endpoint) => {
  const [state, dispatch] = useReducer(asyncReducer, initialState)
  const reduxDispatch = useDispatch()
  let token = useSelector((state) => state.token.value)
  const mountedRef = useRef(true)

  console.log('TOKEN IN USEASYNC', token)

  const { accounts, instance, silentRequest } = useMsal()
  const makeRequest = useCallback(
    async (...props) => {
      let newToken = null
      try {
        const body = props[0]
        dispatch({type: 'pending'})
        const allAccounts = instance.getAllAccounts();
        console.log(allAccounts[0]);

        if (accounts.length > 0 && !token) {
          console.log("this is the first silent acqusition if accts > 0 & !token");
          const request = {
            account: allAccounts[0],
            ...silentRequest
          }
          const token_response = await instance.acquireTokenSilent(request);
          console.log('TOKEN RESPONSE', token_response)
          dispatch(update(token_response.accessToken))
          newToken = token_response.accessToken
        }

        const headers = new Headers()
        const bearer = `Bearer ${newToken || token}`

        console.log('MAKING CALL', endpoint)
        console.log(bearer)

        headers.append("Authorization", bearer)
        headers.append('Access-Control-Allow-Origin', '*')

        const response = await axios({
          method,
          url: `https://letest0-dev.azurewebsites.net/api/${endpoint}`,
          data: body || null,
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*'
          }
        }).catch(async function (error) {
          //console.error("error in catch: ",error)
          if (error.response.status === 401) {
            console.log('401 error and we need to refresh token.')

            const request = {
              account: allAccounts[0],
              scopes: ["https://laconteengineerings.onmicrosoft.com/947dea63-29e0-46c4-85dd-76e191c958af/test"]
            }

            console.log("what are the request variables? ", instance.getActiveAccount());
            const token_response = await instance.acquireTokenSilent(request);
            console.log('TOKEN RESPONSE', token_response)
            dispatch(update(token_response.access_token))
            newToken = token_response.access_token
          }
        });





        // Certain APIs respond with a 200 success code even when they haven't succeeded
        // This bit of logic catches that & throws an error so that they get marked as errors
        if (
          response?.data?.success === false ||
          response?.data?.isSuccess === false
        ) {
          throw response?.data?.message ?? 'An unknown error occurred'
        }
        dispatch({ type: 'success', payload: response })
      } catch (error) {
        dispatch({ type: 'error', payload: error })
      }
    },
    [method, endpoint, accounts, instance, silentRequest, token]
  )

  const resetRequest = useCallback(() => {
    dispatch({ type: 'idle' })
  }, [])

  const value = useMemo(
    () => {
      return [state, makeRequest, resetRequest]
    },
    [state.status, state.error, state.value, makeRequest, resetRequest]
  )

  useEffect(
    () => {
      // When there is a 401 (forbidden) error, open an error dialog
      // We want to handle this in the useAsync hook so that individual apps don't need to handle permission issues
      if (state.status === 'error' && state.error?.status === 401) {
        console.log("error 401.")
        // let loggedInAccount
        // console.log("401 error place")
        // login(instance).then(response => {
        //   console.log("resp= ", response)
        //   instance.setActiveAccount(response[0]);
        //   loggedInAccount = response[0];
        //   const request = {
        //     account: loggedInAccount,
        //     ...silentRequest
        //   }
        //   instance.acquireTokenSilent(request).then(response => {
        //     token =response.access_token
        //     console.log("tokenvar=", token)

        }
    }, [state.status, state.error]
  )

  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  return value
}

export default useAsync