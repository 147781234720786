import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Paper,
  Typography,
  List,
  LitItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TabPanel,
  IconButton
} from '@mui/material'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import EditIcon from '@mui/icons-material/Edit'

import InviteModal from './InviteModal'

import { useSelector, useDispatch } from 'react-redux'

import useAsync from '../../../hooks/useAsync'

const Users = () => {
  const org = useSelector((state) => state.org.currentOrg)
  const [tab, setTab] = useState(0)
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!org.isAdmin) navigate("/")
  }, [])

  const [
    usersRequest,
    getUsers,
    resetGetUsers
  ] = useAsync('get', `organization/users/${org.organizationID}`)

  const [
    invitationsRequest,
    getInvitations,
    resetGetInvitations
  ] = useAsync('get', `invitations/get/${org.organizationID}`)

  useEffect(() => {
    getUsers()
    getInvitations()

    return () => {
      resetGetUsers()
      resetGetInvitations()
    }
  }, [])

  const users = usersRequest?.value?.data?.users
  console.log(users)
  const invitations = invitationsRequest?.value?.data

  console.log(invitations)
  
  return (
    <Box>
      <Box p={2}>
        <Typography variant='h4' color='primary'>Users</Typography>
      </Box>
      <Box>
        <Paper>
          <Box p={2}>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} aria-label="disabled tabs example">
                  <Tab label="Users" value={0} />
                  <Tab label="Invitations" value={1} />
                </Tabs>
              </Box>
              <Box width='100%' display='flex' justifyContent='flex-end'>
                <Button variant='outlined' onClick={() => setModal(true)}>
                  Invite a user
                </Button>
              </Box>
            </Box>
            <Box pt={2}>
              <Paper variant='outlined'>
                {
                  tab === 0 ?
                  <Table>
                    <TableHead sx={{ bgcolor: 'rgb(231, 235, 240)' }}>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          <Typography>
                            Admin
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        users && users.length &&
                        users.map(user => (
                          <TableRow key={user.id}>
                            <TableCell>
                              {user.email}
                            </TableCell>
                            <TableCell align='right'>
                              {
                                user.isAdmin ?
                                <CheckCircleOutlineIcon color='success' />
                                :
                                <HighlightOffIcon color='error' />
                              }
                            </TableCell>
                            <TableCell align='right'>
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  :
                  <Table>
                    <TableHead sx={{ bgcolor: 'rgb(231, 235, 240)' }}>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          <Typography>
                            Status
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          <Typography>
                            Options
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        invitations && invitations.length ?
                        invitations.map(invitation => (
                          <TableRow>
                            <TableCell>
                              {invitation.email}
                            </TableCell>
                            <TableCell align='right'>
                              Pending
                            </TableCell>
                            <TableCell align='right'>

                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <Typography>You don't have any pending invitations.</Typography>
                      }
                    </TableBody>
                  </Table>
                }
              </Paper>
            </Box>
          </Box>
        </Paper>
      </Box>
      {
        modal && <InviteModal modal={modal} setModal={setModal} />
      }
    </Box>
  )
}

export default Users