import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { 
  Box,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  Button,
  Checkbox,
  TextField,
  ToggleButton
} from '@mui/material'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { useTimeSheet } from './timesheetContext'

const TimesheetsList = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedSheets, setSelectedSheets] = useState([])
  const [filtering, setFiltering] = useState(false)
  const [titleFilter, setTitleFilter] = useState('')
  const [startFilter, setStartFilter] = useState(null)
  const [endFilter, setEndFilter] = useState(null)

  const { timeSheets } = useTimeSheet()
  console.log(timeSheets)
  let sheets = timeSheets

  if (filtering) {
    if (titleFilter !== '') {
      sheets = sheets.filter(sheet => sheet.name.includes(titleFilter))
    }
    if (startFilter) {
      sheets = sheets.filter(sheet => sheet.start.getTime() >= startFilter.getTime())
    }
    if (endFilter) {
      sheets = sheets.filter(sheet => sheet.end.getTime() <= endFilter.getTime())
    }
  }

  const clearFilters = () => {
    setFiltering(false)
    setTitleFilter('')
    setStartFilter(null)
    setEndFilter(null)
  }

  const toggleSheet = (checked, id, index) => {
    if (checked) {
      // toggling on
      if (selectedSheets.filter(event => event.id === id).length > 0) return

      setSelectedSheets(oldArray => [...oldArray, {id, index}])
    } else {
      //toggling off
      setSelectedSheets(oldArray => oldArray.filter(item => item.id !== id))
    }
  }

  const maxPages = sheets.length / rowsPerPage

  const indexMin = rowsPerPage * page
  const indexMax = page === Math.floor(maxPages) ? (sheets.length - (page * rowsPerPage)) + indexMin - 1 : page * rowsPerPage + rowsPerPage - 1
  const pageIndices = [...Array(indexMax - indexMin + 1).keys()].map(i => i + indexMin)

  const selectedPageEvents = selectedSheets.filter(event => pageIndices.some(index => index === event.index))

  //const allPageEventsSelected = 

  const handleMasterCheck = () => {
    if (selectedPageEvents.length === pageIndices.length) {
      // Currently all are selected - deselect all
      pageIndices.forEach(index => toggleSheet(false, sheets[index].id, index))
    } else {
      pageIndices.forEach(index => toggleSheet(true, sheets[index].id, index))
    }
  }

  const nextPage = () => {
    if (page < maxPages - 1) {
      setPage(page + 1)
    }
  }

  const previousPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  if (sheets.length && page === Math.ceil(maxPages)) setPage(Math.floor(maxPages))

  return (
    <Box maxWidth='100%'>
      <Box display='flex' alignItems='center' justifyContent='space-between' py={2} pl={2}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h4' color='primary'>Timesheets</Typography>
          <Box pl={1}>
            <Typography>({sheets.length})</Typography>
          </Box>
        </Box>
        <Box pr={2}>
          <ToggleButton
            value="filter"
            selected={filtering}
            onChange={() => {
              setFiltering(!filtering);
            }}
          >
            <FilterAltIcon />
          </ToggleButton>
        </Box>
      </Box>
      <Paper sx={{ textAlign: 'left' }}>
        <Box p={2} display='flex' flexDirection='column' height='100%' justifyContent='space-between'>
          <Box>
            {
              filtering &&
              <Box width='100%' pt={2}>
                <Paper width='100%' variant='outlined'>
                  <Box p={2} display='flex' alignItems='center'>
                    <Box pr={2}>
                      <TextField label='Title' value={titleFilter} onChange={e => setTitleFilter(e.target.value)} />
                    </Box>
                    <Box pr={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label='From'
                          renderInput={(params) => <TextField {...params} />}
                          value={startFilter}
                          onChange={(newValue) => {
                            setStartFilter(newValue)
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label='To'
                          renderInput={(params) => <TextField {...params} />}
                          value={endFilter}
                          onChange={(newValue) => {
                            setEndFilter(newValue)
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box pl={2}>
                      <IconButton onClick={clearFilters}>
                        <ClearIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            }
            {
              sheets.length ?
              <TableContainer component={Paper} variant='outlined' sx={{ marginTop: '16px' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                  <TableHead sx={{ bgcolor: 'rgb(231, 235, 240)' }}>
                    <TableRow>
                      <TableCell style={{ width: '40px' }}>
                        <Checkbox 
                          checked={selectedPageEvents.length > 0} 
                          indeterminate={selectedPageEvents.length === pageIndices.length}
                          onChange={() => handleMasterCheck()}
                        />
                      </TableCell>
                      <TableCell style={{ width: '270px' }}>
                        <Typography>
                          Title
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          Start Date
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          End Date
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {
                          selectedSheets.length > 0 &&
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sheets.map((row, index) => {    
                      console.log(row)                
                      if ((page * rowsPerPage) <= index && (page * rowsPerPage + rowsPerPage) > index) {
                        return (
                          <TableRow
                            hover
                            key={row.name + index}
                            //onClick={e => toggleSheet(!selectedSheets.filter(sheet => sheet.id === row.id).length, row.id, index)}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell sx={{ maxWidth: '50px' }}>
                              <Checkbox 
                                onChange={e => toggleSheet(e.target.checked, row.id, index)}
                                checked={selectedSheets.filter(item => item.id === row.id).length > 0}
                              />
                            </TableCell>
                            <TableCell>
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.start.toLocaleString()}</TableCell>
                            <TableCell align="right">{row.end.toLocaleString()}</TableCell>
                            <TableCell align="right">
                              <IconButton component={Link} to={`/timesheets/${row.sheetID}`}>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <Box p={2}>
                <Typography>
                  No timesheets found.
                </Typography>
              </Box>
            }
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center' pl={2} pt={1}>
              {
                selectedSheets.length > 0 ?
                <Box display='flex' alignItems='center'>
                  <Typography>{selectedSheets.length}/{sheets.length} selected</Typography>
                  <Button variant='outlined' sx={{ marginLeft: '16px' }} onClick={() => setSelectedSheets([])}>Deselect All</Button>
                </Box>
                :
                <Box display='flex' alignItems='center'>
                  <Typography>{sheets.length} items</Typography>
                </Box>
              }
            </Box>
            {
              sheets.length > 0 &&
              <Box p={1} display='flex' alignItems='center' mt={2}>
                <Box display='flex' alignItems='center' pr={2}>
                  <Box pr={1}>
                    <Typography>
                      Rows per page:
                    </Typography>
                  </Box>
                  <Select variant='standard' value={rowsPerPage} onChange={e => {
                    setRowsPerPage(e.target.value)
                    console.log(page, Math.floor(maxPages))
                    if (page === Math.floor(maxPages)) {
                      console.log('setting new max pages', Math.floor(maxPages) - 1)
                      setPage((Math.floor(maxPages) - 1) > 0 ? (Math.floor(maxPages) - 1) : Math.floor(maxPages))
                    }
                  }}>
                    <MenuItem value={5}>
                      5
                    </MenuItem>
                    <MenuItem value={10}>
                      10
                    </MenuItem>
                    {
                      sheets.length > 10 &&
                      <MenuItem value={20}>
                        20
                      </MenuItem>
                    }
                  </Select>
                </Box>
                <Box p={1}>
                  Page: {page + 1} / {Math.ceil(maxPages)}
                </Box>
                <Box alignItems='center'>
                  <IconButton disabled={page === 0} onClick={previousPage}><NavigateBeforeIcon /></IconButton>
                  <IconButton disabled={page === Math.ceil(maxPages) - 1} onClick={nextPage}><NavigateNextIcon /></IconButton>
                </Box>
              </Box>  
            }
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}


export default TimesheetsList