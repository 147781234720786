import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Paper,
  Button,
  Typography,
  TextField
} from '@mui/material'

import { useTime } from '../timeContext'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

import useAsync from '../../../hooks/useAsync'
import { useSnackbar } from '../../../contexts/snackbarContext'

const Import = () => {
  const org = useSelector((state) => state.org.currentOrg)
  const [calendar, setCalendar] = useState(false)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)

  const { getEvents } = useTime()

  const { create } = useSnackbar()

  const [
    importRequest,
    doRequest,
    resetRequest
  ] = useAsync('post', `events/google/${org.organizationID}`)

  const importEvents = () => {
    doRequest({
      calendar: calendar || 'primary',
      start: start.toISOString(),//start.toISOString().split('.')[0] + `-${start.getTimezoneOffset() / 60}:00`,
      end: end.toISOString()//end.toISOString().split('.')[0] + `-${end.getTimezoneOffset() / 60}:00`
    })
  }

  useEffect(() => {
    if (importRequest.status === 'success') {
      create('success', `Imported ${importRequest.value.data.length} events.`)
      getEvents()
    }
    if (importRequest.status === 'error') {
      create('error', 'Failed to import events.')
    }
  }, [importRequest.status])

  console.log(importRequest)

  useEffect(() => {
    return () => resetRequest()
  }, [])

  return (
    <Box>
      <Paper>
        <Box p={2}>
          <Box pb={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label='From'
                renderInput={(params) => <TextField {...params} />}
                value={start}
                onChange={(newValue) => {
                  setStart(newValue)
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box pb={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label='To'
                renderInput={(params) => <TextField {...params} />}
                value={end}
                onChange={(newValue) => {
                  setEnd(newValue)
                }}
              />
            </LocalizationProvider>
          </Box>
          <Button onClick={importEvents}>Import</Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default Import