import React, {useEffect, useState} from 'react'

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import {Box} from '@mui/material'

import Drawer from '../Drawer/AppDrawer'
import Dashboard from '../Dashboard/Dashboard'
import Time from '../Time/Time'
import Requests from '../Requests/Requests'
import NewDev from '../Dev/NewDev'
import Settings from '../Settings/Settings'
import Account from '../Account/Account'
import Bug from '../Bug/Bug'
import Invite from '../Invite/Invite'

// contexts
import TimeContextProvider from '../Time/timeContext'
import TimeSheetProvider from '../Timesheets/timesheetContext'

import FullPageSpinner from '../../components/FullPageSpinner'
import Error from '../../components/Error'

// Timesheets
import Timesheets from '../Timesheets/Timesheets'

// Admin
import Users from '../Admin/Users/Users'

import {useDispatch, useSelector} from 'react-redux'
import {update} from '../../app/tokenSlice'
import {updateCurrent, updateOrgs} from '../../app/orgSlice'
import {updateUser} from '../../app/userSlice'

import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {silentRequest} from '../../authConfig'

import useAsync from '../../hooks/useAsync'

const TheRouter = ({ instance }) => {


  const org = useSelector((state) => state.org.currentOrg)
  const user = useSelector((state) => state.user.value)
  const dispatch = useDispatch()


  console.log('-=-=-=-=-=-=-=-step 1-=-=-=-=-=-=-=-')

    const [
      orgs,
      getOrgs,
      resetOrgs
    ] = useAsync('get', 'user/get/orgs')

    const [
      userRequest,
      getUser,
      resetUser
    ] = useAsync('get', `user`)


  useEffect(() => {
    getOrgs('TEST')
    getUser()

    return () => {
      resetOrgs()
      resetUser()
    }
  }, [])

  useEffect(() => {
    if (orgs?.value?.data) {
      dispatch(updateCurrent(orgs?.value?.data[0]))
      dispatch(updateOrgs(orgs?.value?.data))
    }
  }, [orgs])

  useEffect(() => {
    if (userRequest?.value?.data) {
      dispatch(updateUser(userRequest?.value?.data))
    }
  }, [userRequest])

  if (userRequest.status === 'error') {
    console.log("error in user req")

  }

  if (orgs.status === 'error') {
    return <Error />
  }

  if (orgs.status === 'pending') {
    return <FullPageSpinner />
  }

  if (!org) {
    return (
      <Box
        sx={{ display: 'flex' }}
      >
        <Router>
          <Drawer isAdmin={false} instance={instance}>
            <Routes>
              <Route path='/invite' element={<Invite />} />
              <Route path='*' element={<Box>No org found</Box>} />
            </Routes>
          </Drawer>
        </Router>
      </Box>
    )
  }

  const isAdmin = org && org.isAdmin

  console.log('-=-=-=-=-=-=-=-step 2-=-=-=-=-=-=-=-')

  return (
    <Box
      sx={{ display: 'flex' }}
    >
      <Router>
        <Drawer isAdmin={isAdmin} instance={instance}>
          <Routes>
            <Route path='/users' element={<Users />} />
            <Route path='/timesheets/*' element={<TimeSheetProvider><Timesheets /></TimeSheetProvider>} />
            <Route path='/time/*' element={<TimeContextProvider><Time /></TimeContextProvider>} />
            <Route path='/requests' element={<Requests />} />
            <Route path='/dev' element={<NewDev />} />
            <Route path='/account' element={<Account />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/bug' element={<Bug />} />
            <Route path='/invite' element={<Invite />} />
            <Route path='*' element={<Dashboard />} />
          </Routes>
        </Drawer>
      </Router>
    </Box>
  )
}

async function login(instance) {
  await instance.handleRedirectPromise()
  const accounts = instance.getAllAccounts()
  if (accounts.length === 0) {
    return await instance.loginRedirect([{scopes: ["openid", "profile", "email"]}])
  }
  return accounts
}


export default function AppRouter() {
  const [token, setToken] = useState(false)
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch()
  const { accounts, instance, inProgress } = useMsal()


  useEffect( () => {

    const grabToken = async () => {
      let loggedInAccount;
      let firstSilentRequest = false;
      console.log('-=-=-=-=-=-=-=-step 0-=-=-=-=-=-=-=-')
      if (!accounts || accounts.length === 0) {
        const response1 = await login(instance);
        loggedInAccount = response1[0];
        instance.setActiveAccount(loggedInAccount);
        console.log("logged in")

        const request = {
          account: loggedInAccount,
          ...silentRequest
        }
        console.log("acquiring token silent")
        const response2 = await instance.acquireTokenSilent(request);
        dispatch(update(response2.accessToken));
        setToken(response2.accessToken)
        firstSilentRequest = true;
      }

      if (accounts.length > 0 && firstSilentRequest) {
        const request = {
          account: loggedInAccount,
          ...silentRequest
        }
        console.log("acquiring token silent")
        const response2 = await instance.acquireTokenSilent(request);
        dispatch(update(response2.accessToken));
        setToken(response2.accessToken)
      }

      }
      grabToken().then(r => console.log('grabbed token successfully.')).catch((e => {console.error(e)}))



    },[instance])

  console.log("token outisde == ", token);

  if (!token || !accounts.length) {
    return (
        <FullPageSpinner />
    )
  }



  return <TheRouter instance={instance}/>

}
