import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  CircularProgress
} from '@mui/material'

import useAsync from '../../../hooks/useAsync'
import { useSnackbar } from '../../../contexts/snackbarContext'

const InviteModal = ({ modal, setModal }) => {
  const [email, setEmail] = useState('')
  const org = useSelector((state) => state.org.currentOrg)
  const validEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)

  const { create } = useSnackbar()

  const [
    inviteRequest,
    invite,
    resetInvite
  ] = useAsync('post', `organization/sendinvitation`)

  useEffect(() => {
    return () => resetInvite()
  }, [])

  const handleInvite = () => {
    invite({
      orgId: org.organizationID,
      email: email,
      isAdmin: false
    })
  }

  useEffect(() => {
    if (inviteRequest.status === 'error') {
      create('error', 'Error inviting user')
    }
    if (inviteRequest.status === 'success') {
      create('success', 'Successfully sent invitation')
    }
  }, [inviteRequest.status])

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      onClose={() => setModal(false)}
      open={modal}
    >
      <DialogTitle>
        Invite a user
      </DialogTitle>
      <Box p={2}>
        <Box pb={4} width='100%'>
          <TextField
            label='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Box>
        {
          inviteRequest.status === 'pending' ?
          <CircularProgress />
          :
          <Button disabled={!validEmail} variant='outlined' onClick={handleInvite}>Send</Button>
        }
      </Box>
    </Dialog>
  )
}

export default InviteModal