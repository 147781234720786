import React from 'react'
import {
  Container
} from '@mui/material'

import Organization from './Widgets/Organization/Organization'

export default function Dashboard() {
 return (
  <Container disableGutters>
    <Organization />
  </Container>
 )
}
