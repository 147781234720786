import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Divider,
  TextField
} from '@mui/material'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'

import useAsync from '../../../../../hooks/useAsync'

import { useSnackbar } from '../../../../../contexts/snackbarContext'
import { useTime } from '../../../timeContext'

const EventModal = ({ setEventDialogOpen, eventDialogOpen, event }) => {
  const [newTitle, setNewTitle] = useState(event.title || event.summary)
  const [newStart, setNewStart] = useState(event.start)
  const [newEnd, setNewEnd] = useState(event.end)
  const [newDescription, setNewDescription] = useState(event.description)

  const { getEvents } = useTime()
  const { create } = useSnackbar()

  console.log(event)

  useEffect(() => {
    setNewTitle(event.title || event.summary)
    setNewStart(event.start)
    setNewEnd(event.end)
    setNewDescription(event.description)
  }, [event])

  const [
    updateEventRequest,
    updateEvent,
    resetUpdateEvent
  ] = useAsync('post', `updateEvents`)

  useEffect(() => {
    return () => resetUpdateEvent()
  }, [])

  useEffect(() => {
    if (updateEventRequest.status === 'success') {
      create('success', 'Updated event')
      getEvents()
      setEventDialogOpen(false)
    }

    if (updateEventRequest.status === 'error') {
      create('error', 'Error updating event')
      setEventDialogOpen(false)
    }
  }, [updateEventRequest.status])

  const update = () => {
    delete event.title
    updateEvent({
      newEvent: {
        ...event,
        eventID: event.eventID || event.id,
        summary: newTitle,
        start: newStart,
        end: newEnd,
        description: newDescription
      }
    })
  }

  return (
    <Dialog onClose={() => setEventDialogOpen(false)} open={eventDialogOpen}>
      <Box p={2} minWidth={'400px'}>
        <List>
          <ListItem>
            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
              <TextField value={newTitle} onChange={e => setNewTitle(e.target.value)} label='Title' variant='standard' sx={{ width: '100%' }} />
            </Box>
          </ListItem>
          <ListItem>
            <Box display='flex' width='100%'>
              <TextField value={newDescription} onChange={e => setNewDescription(e.target.value)} label='Description' multiline rows={4} sx={{width: '100%'}} />
            </Box>
          </ListItem>
          <ListItem>
            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DateTimePicker
                    label='Start'
                    renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                    value={newStart}
                    onChange={(newValue) => {
                      setNewStart(newValue)
                    }}
                  />
                </LocalizationProvider>
            </Box>
          </ListItem>
          <ListItem>
            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label='End'
                      renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                      value={newEnd}
                      onChange={(newValue) => {
                        setNewEnd(newValue)
                      }}
                    />
                  </LocalizationProvider>
            </Box>
          </ListItem>
        </List>
        <Box>
          {
            newTitle && newEnd && newStart && (newTitle !== event.title ||
            newStart && newStart !== event.start ||
            newDescription && newDescription !== event.description ||
            newEnd && newEnd !== event.end) &&
            <Box textAlign='center' display='flex' justifyContent='space-evenly'>
              <Button variant='outlined' onClick={update}>Save</Button>
              <Box>
                <Button variant='outlined' color='secondary' onClick={() => setEventDialogOpen(false)}>Cancel</Button>
              </Box>
            </Box>
          }
        </Box>
        
      </Box>
    </Dialog>
  )
}

export default EventModal