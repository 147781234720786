import React from 'react'
import {
  Chip
} from '@mui/material'

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'

const AdminChip = () => {
  return (
    <Chip 
      label='Admin' 
      color='primary' 
      variant='outlined' 
      size='small' 
      icon={<SupervisorAccountIcon />}
    />
  )
}

export default AdminChip