import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress
} from '@mui/material'

import useAsync from '../../../hooks/useAsync'

import { useSnackbar } from '../../../contexts/snackbarContext'
import { useTimeSheet } from '../timesheetContext'

const ExportSheetModal = ({ open, close, id }) => {
  const [existingSheet, setExistingSheet] = useState('new')
  const [url, setUrl] = useState('')

  const { create } = useSnackbar()

  const { getTimeSheets } = useTimeSheet()

  const [
    exportTimesheetRequest,
    exportTimesheet,
    resetExportTimesheet
  ] = useAsync('post', `exportSheet`)

  useEffect(() => {
    return () => resetExportTimesheet()
  }, [])

  useEffect(() => {
    if (exportTimesheetRequest.status === 'success') {
      create('success', 'Successfully exported sheet.')
      getTimeSheets()
      close()
    }

    if (exportTimesheetRequest.status === 'error') {
      create('error', 'Error exporting sheet.')
    }

  }, [exportTimesheetRequest.status])

  const exportSheet = () => {
    if (existingSheet === 'existing') {
      if (url === '') {
        return
      }

      const validUrl = url.includes('/d/')

      if (validUrl) {
        const googleSheetId = url.split('/d/')[1].split('/')[0]
        return exportTimesheet({
          sheetID: id,
          sheetURL: googleSheetId
        })
      }
    }
    
    exportTimesheet({
      sheetID: id
    })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={close}
    >
      <DialogTitle>
        Export TimeSheet
      </DialogTitle>
      <Box p={2}>
        <FormControl>
          <RadioGroup
            value={existingSheet}
            onChange={e => setExistingSheet(e.target.value)}
          >
            <FormControlLabel value='new' control={<Radio />} label='New sheet' />
            <FormControlLabel value='existing' control={<Radio />} label='Append to existing sheet' />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box p={2}>
        <Box pb={2}>
          <Typography>
            The existing sheet url is not needed for new sheets.
          </Typography>
        </Box>
        <TextField 
          label='Existing sheet url' 
          disabled={existingSheet === 'new'} 
          sx={{width: '100%'}} 
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </Box>
      <Box p={2} textAlign='center'>
        {
          exportTimesheetRequest.status === 'pending' ?
          <CircularProgress />
          :
          <Button 
            variant='outlined' 
            disabled={existingSheet === 'existing' && url === ''}
            onClick={exportSheet}
          >
            Export
          </Button>
        }
        
      </Box>
    </Dialog>
  )
}

export default ExportSheetModal