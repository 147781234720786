import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Button,
  Box,
  Typography,
  Paper
} from '@mui/material'

import EventsList from '../../Time/EventsList/EventsList'
import ExportSheetModal from './ExportSheetModal'

import useAsync from '../../../hooks/useAsync'

import { useTimeSheet } from '../timesheetContext'
import { useTime } from '../../Time/timeContext'

const Sheet = () => {
  const { id } = useParams()
  const { timeSheets } = useTimeSheet()
  
  const [exportModal, setExportModal] = useState(false)

  const filtered = timeSheets.filter(sheet => sheet.sheetID === id)

  const sheet = filtered.length ? filtered[0] : false

  console.log(sheet)

  return (
    <div>
      <Box pb={2}>
        <Button variant='outlined' component={Link} to='/timesheets'>
          Back to Timesheets
        </Button>
      </Box>
      {
        sheet ? 
        <Box>
          <Paper>
            <Box>
              <Box p={2} display='flex' justifyContent='space-between'>
                <Typography color='primary' variant='h4'>
                  {sheet.name}
                </Typography>
                <Button variant='outlined' onClick={() => setExportModal(true)}>
                  Export
                </Button>
              </Box>
              <Box px={2}>
                <Paper variant='outlined'>
                  <Box p={2}>
                    <Typography>
                      {`${sheet.start}`}
                    </Typography>
                    <Typography>
                      {`${sheet.end}`}
                    </Typography>
                  </Box>
                  {
                    sheet?.exported?.isExported &&
                    <Box pb={2} pl={2}>
                      <Button variant='outlined' onClick={() => window.open(`${sheet?.exported?.url}`, "_blank")}>
                        View Google Sheet
                      </Button>
                    </Box>
                  }
                </Paper>
              </Box>
              <EventsList passedEvents={sheet.events} bareBones={true} />
            </Box>
          </Paper>
        </Box>
        :
        <Box p={2}>
          <Typography>Timesheet {id} not found.</Typography>
        </Box>
      }
      {
        <ExportSheetModal id={id} open={exportModal} close={() => setExportModal(false)} />
      }
    </div>
  )
}

export default Sheet