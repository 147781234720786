import React from 'react'
import {
  ThemeProvider
} from '@mui/material/styles'

import theme from './theme'

const LEThemeProvider = props => {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}

export default LEThemeProvider