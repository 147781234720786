import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Link,
  useLocation
} from 'react-router-dom'
import {
  Toolbar,
  Divider,
  List,
  Box,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Typography,
  IconButton,
  Button,
  Fab
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'

import HomeIcon from '@mui/icons-material/Home'
import EventIcon from '@mui/icons-material/Event'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PeopleIcon from '@mui/icons-material/People'
import SendIcon from '@mui/icons-material/Send'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

import Avatar from '../misc/Avatar'

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)})`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const drawerWidth = 240

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AppDrawer({ children, topElement, instance }) {
  const org = useSelector((state) => state.org.currentOrg)
  const user = useSelector((state) => state.user.value)
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const theme = useTheme()

  const drawerWidth = 240

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBar position="absolute" open={open} variant='temporary'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box display='flex' width='100%' justifyContent='space-between'>
            <Box marginTop='auto' marginBottom='auto'>
              <img style={{ maxWidth: '50px' }} src={'/LELogo.png'} />
            </Box>
            <Box marginTop='auto' marginBottom='auto'>

            </Box>
            <Box>
              <Typography variant="h6" noWrap component="div">
                <Avatar user={user} instance={instance} />
              </Typography>
            </Box>
          </Box>
          
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box pr={2}>
            <Button component={Link} to='/'>
              {org?.name}
            </Button>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem 
            button
            component={Link}
            to='/'
            selected={location.pathname === '/'}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <ListItem 
            button
            component={Link}
            to='/time/calendar'
            selected={location.pathname === '/time/calendar'}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={'Calendar'} />
          </ListItem>
          <ListItem 
            button
            component={Link}
            to='/time/events'
            selected={location.pathname === '/time/events'}
          >
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary={'Events'} />
          </ListItem>
          <ListItem 
            button
            component={Link}
            to='/timesheets'
            selected={location.pathname === '/timesheets'}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={'Timesheets'} />
          </ListItem>
          {
            /*
            <ListItem 
              button
              component={Link}
              to='/requests'
              selected={location.pathname === '/requests'}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary={'Requests'} />
            </ListItem>
            */
          }
          
        </List>
        {
          org?.isAdmin &&
          <>
            <Divider />
            <List>
              <ListItem 
                button
                component={Link}
                to='/users'
                selected={location.pathname === '/users'}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={'Users'} />
              </ListItem>
            </List>
          </>
        }
      </Drawer>
      <Box component="main" sx={{ bgcolor: '#f5f5f5', width: '100%', maxWidth: '100%', height: '100vh', overflow: 'scroll' }} py={2} minHeight={'100%'}>
        <DrawerHeader />
        <Box maxWidth='100%' px={2}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
