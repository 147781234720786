import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  TextField,
} from '@mui/material'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

import { useTime } from '../../../timeContext'

import useAsync from '../../../../../hooks/useAsync'

import { useSnackbar } from '../../../../../contexts/snackbarContext'

const CreateEventModal = ({ createDialogOpen, setCreateDialogOpen, selected }) => {
  const org = useSelector((state) => state.org.currentOrg)
  const [name, setName] = useState('')
  const [start, setStart] = useState(selected ? selected.start : null)
  const [end, setEnd] = useState(selected ? selected.end : null)

  const { create } = useSnackbar()

  const { setEvents } = useTime()

  const [
    createEventsRequest,
    createEvents,
    resetCreateEvents
  ] = useAsync('post', `events/create/${org.organizationID}`)

  useEffect(() => {
    setStart(selected && selected?.start ? selected.start : null)
    setEnd(selected && selected?.end ? selected.end : null)
  }, [selected])

  useEffect(() => {
    return () => resetCreateEvents()
  }, [])

  useEffect(() => {
    if (createEventsRequest.status === 'success') {
      create('success', 'Event created')
      setEvents(createEventsRequest.value.data)
      setCreateDialogOpen(false)
    }
    if (createEventsRequest.status === 'error') {
      create('error', 'Error creating event')
    }
  }, [createEventsRequest.status])

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      onClose={() => setCreateDialogOpen(false)} 
      open={createDialogOpen}
    >
      <DialogTitle>
        Create an event
      </DialogTitle>
      <Box p={2} pt={1}>
        <Box pb={2} width='100%'>
          <TextField 
            label='Event Name' 
            value={name} 
            onChange={e => setName(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box>
          <TextField
            label='Description'
            multiline
            rows={3}
            sx={{width: '100%'}}
          />
        </Box>
        <Box>
          <Box pt={2}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='Start'
                  renderInput={(params) => <TextField {...params} />}
                  value={start}
                  onChange={(newValue) => {
                    setStart(newValue)
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box py={2}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label='End'
                  renderInput={(params) => <TextField {...params} />}
                  value={end}
                  onChange={(newValue) => {
                    setEnd(newValue)
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Button 
              onClick={() => createEvents({events: [{ summary: name, startDate: start, endDate: end }]})}
              disabled={!name ?? start ?? end}
              variant='outlined'
            >Create Event</Button>
          </Box>  
        </Box>
      </Box>
    </Dialog>
  )
}

export default CreateEventModal