import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#00695c',
    },
    secondary: {
      main: '#ff7043',
    }
  }
})

export default theme