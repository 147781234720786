import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material'

import { useSnackbar } from '../../contexts/snackbarContext'

import useAsync from '../../hooks/useAsync'

const Invite = () => {
  const [searchParams] = useSearchParams()
  const { create } = useSnackbar()
  const navigate = useNavigate()

  const orgID = searchParams.get('org')
  const invID = searchParams.get('inv')
  const email = searchParams.get('email')

  const [
    acceptInviteRequest,
    acceptInvite,
    resetAcceptInvite
  ] = useAsync('get', `invite?org=${orgID}&inv=${invID}&email=${email}`)

  useEffect(() => {
    if (orgID && invID && email) {
      acceptInvite()
    }
    return () => resetAcceptInvite()
  }, [])

  if (acceptInviteRequest.status === 'pending') {
    return (
      <Box width='100%' textAlign='center'>
        <CircularProgress size={80}/>
      </Box>
    )
  }

  console.log(acceptInviteRequest)

  if (acceptInviteRequest.status === 'error') {
    create('error', acceptInviteRequest?.error?.response?.data)
    navigate('/')
  }

  if (acceptInviteRequest.status === 'success') {
    create('success', acceptInviteRequest?.value?.data)
    navigate('/')
  }

  return <div>
    {
      !orgID && <p>Missing org</p>
    }
    {
      !invID && <p>Missing inv</p>
    }
    {
      !email && <p>Missing email</p>
    }
  </div>
}

export default Invite