import React from "react"

import './App.css'

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"

import SignIn from "./Pages/SignIn/SignIn"

import SnackBar from "./components/SnackBar"

import AppRouter from "./Pages/AppRouter/AppRouter"

function App({ msalInstance }) {
  
  return (
    <MsalProvider instance={msalInstance}>
      <AppRouter />
      <SnackBar />
    </MsalProvider>
  )
}

export default App