import { createSlice } from '@reduxjs/toolkit'

export const orgSlice = createSlice({
  name: 'org',
  initialState: {
    currentOrg: false,
    orgs: []
  },
  reducers: {
    updateCurrent: (state, action) => {
      state.currentOrg = action.payload
    },
    updateOrgs: (state, action) => {
      state.orgs = action.payload
    }
  }
})

export const { updateCurrent, updateOrgs } = orgSlice.actions

export default orgSlice.reducer