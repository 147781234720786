import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem
} from '@mui/material'

import useAsync from '../../hooks/useAsync'

const NewDev = () => {
  const [method, setMethod] = useState('get')
  const [url, setUrl] = useState('')
  const [body, setBody] = useState('')

  const [
    data,
    makeRequest,
    resetRequest
  ] = useAsync(method, url)

  useEffect(() => {
    console.log('*--------------------------------*')
    console.log('url:', url)
    console.log(data?.status)
    console.log(data?.value)
    console.log('*-----------------*')
  }, [data?.status])

  

  return (
    <Box>
      <Select
        value={method}
        onChange={e => setMethod(e.target.value)}
      >
        <MenuItem value={'get'}>GET</MenuItem>
        <MenuItem value={'post'}>POST</MenuItem>
      </Select>
      <TextField label='url' value={url} placeholder='getEvents/...' onChange={e => setUrl(e.target.value)}/>
      <TextField label='body' autoComplete='off' value={body} onChange={e => setBody(e.target.value)}/>
      <Button onClick={() => makeRequest(body)}>Do</Button>
    </Box>
  )
}

export default NewDev