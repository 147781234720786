import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import useAsync from '../../hooks/useAsync'

const initialState = {
  isLoading: true,
  hasError: false,
  events: []
}

export const TimeContext = React.createContext(initialState)

const TimeProvider = ({ children }) => {
  const org = useSelector((state) => state.org.currentOrg)
  const [events, setEvents] = useState([])

  const [
    eventsRequest,
    getEvents,
    resetEvents
  ] = useAsync('get', `events/user/get/${org.organizationID}`)

  const [
    createTimesheetRequest,
    createTimesheet,
    resetCreateTimesheet
  ] = useAsync('post', `timesheet/create/${org.organizationID}`)

  useEffect(() => {
    getEvents()
    return () => {
      resetEvents()
      resetCreateTimesheet()
    }
  }, [])

  useEffect(() => {
    if (eventsRequest.status === 'success' && eventsRequest?.value?.data !== null) {
      let newEvents = []
      let newStarts = []
      let newEnds = []
      eventsRequest.value.data.forEach(event => {
        const start = typeof event.start === 'string' ? {
          year: event.start.split('-')[0],
          month: event.start.split('-')[1],
          time: event.start.split('-')[2]
        } : event.start
        const end = typeof event.end === 'string' ? {
          year: event.end.split('-')[0],
          month: event.end.split('-')[1],
          time: event.end.split('-')[2]
        } : event.end
        console.log('------')
        console.log(event)
        console.log(start)
        console.log(end)

        newEvents.push({
          summary: event.summary,
          start: new Date(`${start.year}-${start.month}-${start.time}`),
          end: new Date(`${end.year}-${end.month}-${end.time}`),
          id: event.id,
          eventID: event.eventID,
          ...event
        })
        newStarts.push(event.start)
        newEnds.push(event.end)
      })
      setEvents(newEvents)
    }
  }, [eventsRequest?.value?.data])

  return (
    <TimeContext.Provider
      value={{
        events,
        isLoading: eventsRequest.status === 'pending',
        hasError: eventsRequest.status === 'error',
        createTimesheetRequest,
        createTimesheet,
        getEvents,
        setEvents
      }}
    >
      {children}
    </TimeContext.Provider>
  )
}

export const useTime = () => useContext(TimeContext)

export default TimeProvider