import React, { useEffect, useState } from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  Box,
  Toolbar,
  Typography
} from '@mui/material'

import moment from 'moment'

import { useTime } from './timeContext'

import EventsList from './EventsList/EventsList'
import SimpleEvents from './EventsList/SimpleEvents'
import NewCalendar from './Calendar/NewCalendar'
import Import from './Import/Import'

import useAsync from '../../hooks/useAsync'
import LoadingSkeleton from '../../components/LoadingSkeleton'
import Error from '../../components/Error'

export default function Time() {
  const {
    events,
    isLoading,
    hasError
  } = useTime()


  if (hasError) {
    return <Error />
  }

  if (isLoading) {
    return <LoadingSkeleton />
  }
  
  console.log(events)
  
  return (
    <Box>
      <Routes>
        <Route path='/calendar' element={<NewCalendar events={events} />} />
        <Route path='/events' element={<SimpleEvents events={events} />} />
        <Route path='/import' element={<Import />} />
      </Routes>
    </Box>
  )
}
