import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  Typography
} from '@mui/material'
import { Calendar as ReactCalendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import * as dates from './dates'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { useTime } from '../timeContext'

import CreateEventModal from './components/modals/CreateEventModal'

import useAsync from '../../../hooks/useAsync'
import EventModal from './components/modals/EventModal'

const localizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      //backgroundColor: 'lightblue',
    },
  })

const Calendar = () => {
  const [eventDialogOpen, setEventDialogOpen] = useState(false)
  const [event, setEvent] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [selected, setSelected] = useState(false)

  const { events } = useTime()

  const handleEventClick = event => {
    setEvent(event)
    setEventDialogOpen(true)
  }

  const handleEmptyClick = (e) => {
    setSelected(e)
    setCreateDialogOpen(true)
  }
  
  const newEvents = events && events.map(event => {

    return {
      title: event.summary,
      start: Date(event.start),
      end: Date(event.end),
      id: event.id,
      ...event
    }
  })

  console.log(newEvents)

  return (
    <Box height='800px'>
      <ReactCalendar
        events={newEvents || []}
        views={['month', 'week', 'day']}
        step={60}
        showMultiDayTimes
        components={{
          timeSlotWrapper: ColoredDateCellWrapper,
        }}
        selectable={true}
        onSelectSlot={e => handleEmptyClick(e)}
        onSelectEvent={event => handleEventClick(event)}
        localizer={localizer}
        popup
        messages={{
          showMore: total => (
            <div
              style={{ cursor: 'pointer' }}
              onMouseOver={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >{`+${total} more`}
            </div>
          ),
        }}
      />
      <EventModal
        setEventDialogOpen={setEventDialogOpen}
        eventDialogOpen={eventDialogOpen}
        event={event}
      />
      <CreateEventModal 
        createDialogOpen={createDialogOpen} 
        setCreateDialogOpen={setCreateDialogOpen}
        selected={selected}
      />
    </Box>
  )
}

export default Calendar