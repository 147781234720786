import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Paper,
  TextField,
  Typography
} from '@mui/material'

import { useTime } from '../timeContext'

import { useSnackbar } from '../../../contexts/snackbarContext'

const CreateSheetModal = ({ createTimesheetDialog, closeDialog, events }) => {
  const [name, setName] = useState('')

  const { create } = useSnackbar()
  const { createTimesheet, createTimesheetRequest } = useTime()
  console.log(events)

  useEffect(() => {
    if (createTimesheetRequest.status === 'success') {
      create('success', 'Timesheet created')
      closeDialog()
    }
  }, [createTimesheetRequest])

  const isValid = name.length

  console.log(events)

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={createTimesheetDialog}
      onClose={closeDialog}
    >
      <DialogTitle>
        Create Timesheet
      </DialogTitle>
      <Box p={2} pt={1}>
        <Box pb={2} width='100%'>
          <TextField
            label='Title'
            value={name}
            onChange={e => setName(e.target.value)}
            sx={{width: '100%'}}
          />
        </Box>
        <Box>
          <Typography>
            Events({events.length}):
          </Typography>
        </Box>
        <Box>
          <Paper variant='outlined'>
            <Box p={1} maxHeight='400px' overflow='scroll'>
              {
                events.map((event, i) => {
                  return (
                    <Box pb={1} key={event.summary + i}>
                      <Paper variant='outlined'>
                        <Box px={1}>
                          <Typography>
                            {event.summary}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                    
                  )
                })
              }
            </Box>
          </Paper>
        </Box>
        <Box pt={2}>
          <Button disabled={!isValid} variant='outlined' onClick={() => createTimesheet({
            sheetName: name,
            events: events
          })}>
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CreateSheetModal