import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: false,
    expiration: false
  },
  reducers: {
    setExpiration: (state, action) => {
      state.expiration = action.payload
    },
    update: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, update } = counterSlice.actions

export default counterSlice.reducer