import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Paper,
  Avatar,
  Typography,
  Container,
  List,
  ListItem,
  Divider,
  Slide,
  Button,
  IconButton
} from '@mui/material'

import AdminChip from '../../components/AdminChip'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const Account = () => {
  const [showOrgs, setShowOrgs] = useState(false)
  const containerRef = React.useRef(null)
  const user = useSelector((state) => state.user.value)

  if (!user) return null

  return (
    <Container disableGutters>
      <Box>
        <Paper>
          <Box p={2} py={4}>
            <Box display='flex' justifyContent='space-around'>
              <Avatar 
                src={user.pictureURL} 
                alt={user.userID}
                sx={{ height: 80, width: 80 }}
              >{user.userID}</Avatar>
            </Box>
            <Box display='flex' justifyContent='space-around' pt={2}>
              <Box width='400px' ref={containerRef} overflow='hidden'>
                <Paper variant='outlined'>
                  <Box p={2} display='flex'>
                    <Slide
                      in={showOrgs}
                      direction='left'
                      container={containerRef.current}
                      unmountOnExit
                      mountOnEnter
                    >
                      {
                        showOrgs ?
                        <Box width='100%'>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          <Button variant='outlined' onClick={() => setShowOrgs(false)}>
                            <ArrowBackIcon />
                          </Button>
                          <Typography>
                            Organizations
                          </Typography>
                          <Box width='36px'></Box>
                        </Box>
                        <List>
                          {
                            user?.organizations.map((org, i) => {
                              return (
                                <React.Fragment key={org + i + 'divider'}>
                                  {
                                    i !== 0 &&
                                    <Divider />
                                  }
                                  <ListItem>
                                    <Box display='flex' justifyContent='space-between' width='100%'>
                                      <Typography>
                                        {org?.name}
                                      </Typography>
                                      {
                                        org?.isAdmin &&
                                        <AdminChip />
                                      }
                                    </Box>
                                  </ListItem>
                                </React.Fragment>
                              )
                            })
                          }
                        </List>
                      </Box>
                      :
                      <Box></Box>
                      }
                      
                    </Slide>
                    {
                      <Slide
                      in={!showOrgs}
                      direction='right'
                      container={containerRef.current}
                      unmountOnExit
                      mountOnEnter
                      >
                        {
                          !showOrgs ?
                          <Box width='100%'>
                        <List>
                          <ListItem>
                            <Box display='flex' justifyContent='space-between' width='100%'>
                              <Typography>
                                Email:
                              </Typography>
                              <Typography>
                                {user.email}
                              </Typography>
                            </Box>
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <Box display='flex' justifyContent='space-between' width='100%'>
                              <Typography>
                                Google ID:
                              </Typography>
                              <Typography>
                                {user.userID}
                              </Typography>
                            </Box>
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            onClick={() => setShowOrgs(true)}
                          >
                            <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                              <Typography>
                                Organizations
                              </Typography>
                                <ChevronRightIcon />
                            </Box>
                          </ListItem>
                        </List>
                      </Box>
                      :
                      <Box></Box>
                        }
                        
                      </Slide>
                      
                    }
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default Account