import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import useAsync from '../../hooks/useAsync'

const initialState = {
  isLoading: true,
  hasError: false,
  timeSheets: []
}

export const TimeSheetContext = React.createContext(initialState)

const TimeSheetProvider = ({ children }) => {
  const org = useSelector((state) => state.org.currentOrg)
  const [timeSheets, setTimeSheets] = useState([])

  const [
    timeSheetsRequest,
    getTimeSheets,
    resetRequest
] = useAsync('get', `timesheets/${org.organizationID}`)

  useEffect(() => {
    getTimeSheets()
    return () => {
      resetRequest()
    }
  }, [])

  useEffect(() => {
    if (timeSheetsRequest.status === 'success' && timeSheetsRequest?.value?.data !== null) {
      let newSheets = []
      console.log(timeSheetsRequest.value.data)
      timeSheetsRequest.value.data.forEach(event => {
        console.log(event)
        const start = typeof event.start === 'string' ? {
          year: event.start.split('-')[0],
          month: event.start.split('-')[1],
          time: event.start.split('-')[2]
        } : event.start
        const end = typeof event.end === 'string' ? {
          year: event.end.split('-')[0],
          month: event.end.split('-')[1],
          time: event.end.split('-')[2]
        } : event.end
        console.log(start)
        console.log(end)
        if (!start?.year || !end?.year) return null
        newSheets.push({
          name: event.name,
          start: new Date(`${start.year}-${start.month}-${start.time}`),
          end: new Date(`${end.year}-${end.month}-${end.time}`),
          id: event.id,
          sheetID: event.sheetID,
          events: event.events,
          orgID: event.orgID,
          userID: event.userID,
          users: event.users,
          exported: {
            isExported: event?.exportedSheetURL ? true : false,
            url: event?.exportedSheetURL || null,
            googleID: event?.googleSpreadSheetID || null
          }
        })
      })
      setTimeSheets(newSheets)
    }
    console.log(timeSheets)
  }, [timeSheetsRequest?.value?.data])

  return (
    <TimeSheetContext.Provider
      value={{
        timeSheets,
        isLoading: timeSheetsRequest.status === 'pending',
        hasError: timeSheetsRequest.status === 'error',
        getTimeSheets
      }}
    >
      {children}
    </TimeSheetContext.Provider>
  )
}

export const useTimeSheet = () => useContext(TimeSheetContext)

export default TimeSheetProvider