import React from 'react'
import {
  Box,
  Typography,
  Icon,
  Paper,
  Container,
  Button
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const Error = () => {
  return (
    <Container sx={{paddingTop: '16px'}}>
      <Box>
        <Paper>
          <Box p={2}>
            <Box width='100%' textAlign='center' pb={2}>
              <ErrorOutlineIcon color='error' sx={{ fontSize: '60px' }} />
            </Box>
            <Box>
              <Typography>
                Something went wrong, please try again by refreshing the page.
              </Typography>
              <Typography>
                If the issue persists, please contact richl.laconte@gmail.com
              </Typography>
            </Box>
            <Box textAlign='center' pt={2}>
              <Button variant='outlined' onClick={() => window.location.reload()}>Refresh</Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default Error