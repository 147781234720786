import React, { useContext, useState } from 'react'

const Context = React.createContext({})

const SnackbarContextProvider = ({ children }) => {
  const [snack, setSnack] = useState(false)

  const create = (variant, content) => {
    console.log('create called', content)
    setSnack({
      variant,
      content
    })
  }

  const clear = () => {
    setSnack(false)
  }

  const value = {
    snack,
    create,
    clear
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default SnackbarContextProvider
export const useSnackbar = () => useContext(Context)